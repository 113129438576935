// Generated by Framer (91bcfbd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["STswMU5aK", "ceRtr32NL"];

const serializationHash = "framer-MCHHl"

const variantClassNames = {ceRtr32NL: "framer-v-13x7sdy", STswMU5aK: "framer-v-12jj6ua"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {ceRtr32NL: {delay: 0.2, duration: 2, ease: [0.25, 1, 0.5, 1], type: "tween"}, default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Line Animation Active": "ceRtr32NL", "Line Animation": "STswMU5aK"}

const getProps = ({background, height, id, width, ...props}) => { return {...props, nhvtjR0LA: background ?? props.nhvtjR0LA ?? "var(--token-5561bb3f-a718-41fb-9058-813f188fd34f, rgb(55, 43, 41))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "STswMU5aK"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nhvtjR0LA, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "STswMU5aK", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12jj6ua", className, classNames)} data-framer-name={"Line Animation"} layoutDependency={layoutDependency} layoutId={"STswMU5aK"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ceRtr32NL: {"data-framer-name": "Line Animation Active"}}, baseVariant, gestureVariant)}><motion.div className={"framer-lhn4bh"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"L13JIEqnG"} style={{backgroundColor: nhvtjR0LA}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-MCHHl[data-border=\"true\"]::after, .framer-MCHHl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MCHHl.framer-udqbpu, .framer-MCHHl .framer-udqbpu { display: block; }", ".framer-MCHHl.framer-12jj6ua { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 787px; }", ".framer-MCHHl .framer-lhn4bh { flex: none; height: 1px; position: relative; width: 0%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MCHHl.framer-12jj6ua { gap: 0px; } .framer-MCHHl.framer-12jj6ua > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-MCHHl.framer-12jj6ua > :first-child { margin-left: 0px; } .framer-MCHHl.framer-12jj6ua > :last-child { margin-right: 0px; } }", ".framer-MCHHl.framer-v-13x7sdy .framer-lhn4bh { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 787
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ceRtr32NL":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nhvtjR0LA":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerMSBSlfarY: React.ComponentType<Props> = withCSS(Component, css, "framer-MCHHl") as typeof Component;
export default FramerMSBSlfarY;

FramerMSBSlfarY.displayName = "Line Animation - Career Section";

FramerMSBSlfarY.defaultProps = {height: 1, width: 787};

addPropertyControls(FramerMSBSlfarY, {variant: {options: ["STswMU5aK", "ceRtr32NL"], optionTitles: ["Line Animation", "Line Animation Active"], title: "Variant", type: ControlType.Enum}, nhvtjR0LA: {defaultValue: "var(--token-5561bb3f-a718-41fb-9058-813f188fd34f, rgb(55, 43, 41)) /* {\"name\":\"EH Brown\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerMSBSlfarY, [])